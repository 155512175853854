import React from "react";
import { FrameStyle, RageLogo, ConentFrame } from "./App.styles";

function App() {
  return (
    <FrameStyle>
      <ConentFrame>
        <RageLogo />
      </ConentFrame>
    </FrameStyle>
  );
}

export default App;
